<template>
  <div
    v-if="!isConnectedToChannel"
    class="fixed w-full text-center text-sm bg-white border-t -mt-10 p-2 shadow-md z-20"
    :class="{
      'connected-to-channel-warning': modalLayout,
    }"
  >
    <span>
      {{ $t("Not connected. Real time events may not work. ") }}
    </span>
    <span
      class="underline cursor-pointer"
      @click="tryJoinChannel(true)"
    >
      {{ $t("Retry connection" )}}
    </span>
  </div>
  <InfiniteScroll
    :key="`retry_count_${retryCount}`"
    :load-next="loadNextPage"
    :distance="70"
    direction="up"
    loaderClass="hidden"
  >
    <template #spinner>
      <span />
    </template>
    <div
      class="max-w-4xl flex flex-col m-auto bg-white overflow-hidden rounded-none sm:rounded-lg sm:rounded-b-none"
      :class="{
        'modal-layout-comments-container overflow-auto': modalLayout,
        'project-discussion-comments-container': !modalLayout,
        'border border-gray-200 shadow p-3 sm:p-6 pb-36': !$useNewLayout,
        'pb-36 sm:pb-0': $useNewLayout,
      }"
    >
      <div
        class="flex-1 space-y-6"
        :class="{
        'h-auto overflow-auto': modalLayout
      }"
      >
        <template v-if="loading && discussions.length === 0">
          <LoadingComment />
          <LoadingComment />
        </template>
        <NoDiscussionComments
          v-if="!loading && !discussions.length"
          class="text-center text-gray-300 mt-4"
        />
        <DiscussionComment
          v-for="discussion of discussions"
          :key="discussion.id"
          :discussion="discussion"
          editable
          :readonly="isCurrentProjectClosed"
          @update="updateComment"
          @delete="deleteComment"
        />
        <div id="bottom-comments-row" />
      </div>
    </div>
  </InfiniteScroll>
  <div
    v-if="!isCurrentProjectClosed"
    class="w-full fixed sm:sticky left-0 sm:left-[unset] bg-white max-w-4xl mx-auto rounded-lg"
    :class="{
      'bottom-0 sm:bottom-2': $useNewLayout,
      'bottom-16 mb-2 sm:bottom-2 md:mb-0': !$useNewLayout,
    }"
  >
    <HtmlCommentInput
      :sendAction="sendComment"
      :isConnectedToChannel="isConnectedToChannel"
      :usersTyping="usersTyping"
      :notifyIsTyping="notifyIsTyping"
      :projectId="projectId"
      :infoText="$t('A notification will be sent to all people who are part of this project and have notifications turned on.')"
    />
  </div>
</template>
<script>
// Components
import DiscussionComment from "@/modules/common/components/DiscussionComment.vue";
import InfiniteScroll from "@/components/common/InfiniteScroll.vue";
import LoadingComment from "@/modules/common/components/LoadingComment.vue";
import HtmlCommentInput from "@/components/form/HtmlCommentInput.vue"
import NoDiscussionComments from "@/modules/common/components/NoDiscussionComments.vue";

// Helpers
import { useRoute } from 'vue-router'
import PusherUtils from "@/modules/common/utils/pusherUtils"
import { useDiscussion } from "@/modules/projects/composables/useDiscussion.js";

export default {
  components: {
    LoadingComment,
    InfiniteScroll,
    HtmlCommentInput,
    DiscussionComment,
    NoDiscussionComments,
  },
  props: {
    modalLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
    }
  },
  setup() {
    const route = useRoute()
    const projectId = route.params.id || route.query.projectId
    const {
      usersTyping,
      retryCount,
      isConnectedToChannel,
      listenChannel,
      triggerChannel,
      tryJoinChannel,
      notifyIsTyping,
    } = useDiscussion({ projectId })

    return {
      usersTyping,
      retryCount,
      isConnectedToChannel,
      listenChannel,
      triggerChannel,
      tryJoinChannel,
      notifyIsTyping,
    }
  },
  computed: {
    projectId() {
      return this.$route.params.id || this.$route.query.projectId
    },
    discussions() {
      return this.$store.state.discussions.projectDiscussion
    },
    loading() {
      return this.$store.state.discussions.projectDiscussionLoading
    },
    isCurrentProjectClosed() {
      return this.$store.getters['projects/isCurrentProjectClosed']
    }
  },
  methods: {
    async loadNextPage($state) {
      if (this.loading) {
        return;
      }

      try {
        const result = await this.$store.dispatch('discussions/getProjectDiscussion', {
          projectId: this.projectId,
          page: this.page
        })

        $state.loaded()

        if (this.page === 1 && result.meta.total > 0) {
          setTimeout(this.scrollToNewestComment, 100)
        }

        if (result.meta.current_page === result.meta.last_page) {
          $state.complete()
        } else {
          this.page++
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not load more comments'))
      }
    },
    async sendComment({ message, notifiable_user_ids }) {
      const newAddedComment = await this.$store.dispatch('discussions/createProjectDiscussionComment', {
        projectId: this.projectId,
        message,
        notifiable_user_ids,
        isConnectedToChannel: this.isConnectedToChannel
      })
      this.triggerChannel?.trigger(PusherUtils.COMMENT_ADDED_EVENT, newAddedComment)
      await this.scrollToNewestComment()
    },
    async updateComment({ comment, message, notifiable_user_ids }) {
      const updatedComment = await this.$store.dispatch('discussions/updateProjectDiscussionComment', {
        comment,
        message,
        notifiable_user_ids,
      })

      this.triggerChannel?.trigger(PusherUtils.COMMENT_UPDATED_EVENT, updatedComment)
    },
    async deleteComment(comment) {
      await this.$store.dispatch('discussions/deleteProjectDiscussionComment', {
        commentId: comment.id
      })

      this.triggerChannel?.trigger(PusherUtils.COMMENT_DELETED_EVENT, comment.id)
    },
    async scrollToNewestComment() {
      await this.$nextTick()
      const bottomCommentsRow = document.getElementById('bottom-comments-row')
      bottomCommentsRow?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    initChannelEvents() {
      this.page = 1

      this.listenChannel.on(PusherUtils.COMMENT_ADDED_EVENT, (comment) => {
        this.$store.commit('discussions/addCommentToProjectDiscussion', comment)
        this.scrollToNewestComment()
      })

      this.listenChannel.on(PusherUtils.COMMENT_DELETED_EVENT, id => {
        this.$store.commit('discussions/removeCommentFromDiscussion', id)
      })

      this.listenChannel.on(PusherUtils.COMMENT_UPDATED_EVENT, updatedComment => {
        this.$store.commit('discussions/updateDiscussionComment', updatedComment)
      })
    },
    markNotificationsRead() {
      this.$store.dispatch('notifications/markEntityNotificationsRead', {
        notifiable_target_type: 'project_discussion',
        notifiable_target_id: this.projectId,
      })
    }
  },
  mounted() {
    this.initChannelEvents()
    this.markNotificationsRead()
    document.body.classList.add('overflow-x-hidden')
    this.scrollToNewestComment()
  },
}
</script>
<style scoped>
.project-discussion-comments-container {
  min-height: calc(100vh - 140px);

}
.modal-layout-comments-container {
  @apply border-none shadow-none sm:px-0;
  max-height: calc(100vh - 300px);
}

.connected-to-channel-warning {
  @apply absolute border-none bg-transparent shadow-none font-semibold;
}
</style>
